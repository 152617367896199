import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Amount, AmountProps } from '../_ui/Amount/Amount'

interface Props extends Omit<AmountProps, 'suffix'> {
  includingTax: boolean
}

export const AmountWithTax = ({ includingTax, ...props }: Props) => {
  const { t } = useTranslation('common')

  return (
    <Amount
      {...props}
      suffix={t(includingTax ? 'includingTax' : 'excludingTax')}
    />
  )
}
