import { mergeClasses } from '@blank/utilities'
import { extractAmount } from 'modules/common/lib/helpers/amount'

export const AMOUNT_SIZES = ['sm', 'base', 'md', 'lg', 'xl', '2xl'] as const
export const DISPLAY_DECIMAL_VARIANTS = [
  'always',
  'never',
  'ignoreZero',
] as const

type Size = (typeof AMOUNT_SIZES)[number]
type DisplayDecimalVariant = (typeof DISPLAY_DECIMAL_VARIANTS)[number]

export interface AmountProps {
  amount: number | undefined
  className?: string
  size?: Size
  prefixClassName?: string
  suffixClassName?: string
  prefix?: string
  suffix?: string
  displayDecimal?: DisplayDecimalVariant
  fontWeight?: 'normal' | 'bold'
  withAsterisk?: boolean
}

export const Amount = ({
  amount,
  className,
  size = 'base',
  suffixClassName,
  prefixClassName,
  prefix,
  suffix,
  displayDecimal = 'always',
  fontWeight = 'bold',
  withAsterisk = false,
}: AmountProps) => {
  const { symbol, decimal, integer, sign } = extractAmount(amount)
  const withDecimal =
    displayDecimal === 'always' ||
    (displayDecimal === 'ignoreZero' && decimal !== '00')

  return (
    <span
      data-testid="Amount"
      className={mergeClasses(
        'whitespace-nowrap font-amount',
        {
          'text-info-300': amount === undefined,
          'font-bold': fontWeight === 'bold',
          'font-normal': fontWeight === 'normal',
        },
        className
      )}
    >
      <span
        className={mergeClasses({
          'text-sm': size === 'sm',
          'text-base': size === 'base',
          'text-[28px] leading-[32px]': size === 'md',
          'text-[38px] leading-[40px]': size === 'lg',
          'text-[48px] leading-[56px]': size === 'xl',
          'text-[60px] leading-[60px]': size === '2xl',
        })}
      >
        {prefix && <span className={prefixClassName}>{`${prefix} `}</span>}
        {sign === '-' && sign}
        {amount === undefined ? '----' : integer}
      </span>
      <span
        className={mergeClasses({
          'text-[14px]': size === 'sm',
          'text-[16px]': size === 'base',
          'text-[18px]': size === 'md',
          'text-[25px]': size === 'lg',
          'text-[32px]': size === 'xl',
          'text-[40px]': size === '2xl',
        })}
      >
        {withDecimal && `,${amount === undefined ? '--' : decimal}`}
        {withAsterisk && (
          <span
            className={mergeClasses('align-top', {
              'text-[7px]': size === 'sm',
              'text-[8px]': size === 'base',
              'text-[9px]': size === 'md',
              'text-[12px]': size === 'lg',
              'text-[16px]': size === 'xl' || size === '2xl',
            })}
          >
            *
          </span>
        )}
        <span>{` ${symbol}`}</span>
        {suffix && <span className={suffixClassName}>{` ${suffix}`}</span>}
      </span>
    </span>
  )
}
