import {
  DocumentApi,
  DocumentItemApi,
  EnumDocumentStatus,
  EnumDocumentType,
  InvoicingSettingsApi,
  InvoicingSettingsDisplayIbanApi,
} from '@blank/api'

export type DocumentRow = Pick<
  DocumentApi,
  | 'id'
  | 'createdAt'
  | 'dueDate'
  | 'isLate'
  | 'status'
  | 'totalWithoutVAT'
  | 'documentNumber'
  | 'title'
  | 'type'
> & {
  customerName: string
}

export type DocumentItemPayload = Pick<
  DocumentItemApi,
  | 'currency'
  | 'description'
  | 'name'
  | 'quantity'
  | 'unit'
  | 'unitPriceWithoutVAT'
  | 'VATRate'
>

export type PostDocumentPayload = Pick<DocumentApi, 'type'> &
  Partial<
    Pick<
      DocumentApi,
      | 'corporateId'
      | 'discountRate'
      | 'documentNumber'
      | 'title'
      | 'customerId'
      | 'pensionContribution'
      | 'withHolding'
      | 'fiscalStampPrice'
      | 'status'
    >
  > & {
    items: DocumentItemPayload[]
    settings: Pick<
      InvoicingSettingsApi,
      | 'estimateAcceptationDays'
      | 'estimateAdditionnalMention'
      | 'estimateAcceptationTerms'
      | 'invoicePaymentDays'
      | 'invoiceAdditionnalMention'
      | 'invoicePaymentTerms'
      | 'footer'
    > & {
      displayIBAN?: Pick<InvoicingSettingsDisplayIbanApi, 'display' | 'type'>
    }
  }

export type PostDocumentPreviewPayload = Omit<PostDocumentPayload, 'status'>

export type PatchDocumentPayload = Partial<
  Omit<PostDocumentPayload, 'corporateId'>
> & {
  status?: EnumDocumentStatus
}

export interface PostValidateDocumentPayload {
  validatedAt: string
  documentNumber: string
  estimateAcceptationDays?: number
  invoicePaymentDays?: number
  corporateId?: string
}

export const InvoiceStatusTypes = Object.values(EnumDocumentStatus).filter(
  (value) => value !== EnumDocumentStatus.ACCEPTED
)

export const EstimateStatusTypes = Object.values(EnumDocumentStatus).filter(
  (value) => value !== EnumDocumentStatus.PAID
)

export enum EnumDocumentTabs {
  INVOICES_TAB = 'invoiceDocuments',
  ESTIMATES_TAB = 'estimateDocuments',
}

export enum EnumDocumentTypeSlugs {
  INVOICE_SLUG = 'facture',
  ESTIMATE_SLUG = 'devis',
}

export enum EnumDocumentFormStep {
  INFORMATION = 'INFORMATION',
  SETTINGS = 'SETTINGS',
}

export const DOCUMENT_SLUG_TYPES = {
  [EnumDocumentTypeSlugs.INVOICE_SLUG]: EnumDocumentType.INVOICE,
  [EnumDocumentTypeSlugs.ESTIMATE_SLUG]: EnumDocumentType.ESTIMATE,
}

export const DOCUMENT_TYPE_SLUGS = {
  [EnumDocumentType.INVOICE]: EnumDocumentTypeSlugs.INVOICE_SLUG,
  [EnumDocumentType.ESTIMATE]: EnumDocumentTypeSlugs.ESTIMATE_SLUG,
}

export const INVOICING_SETTINGS_DELAY_PROPERTY_NAME: Record<
  EnumDocumentType,
  Extract<
    keyof InvoicingSettingsApi,
    'estimateAcceptationDays' | 'invoicePaymentDays'
  >
> = {
  [EnumDocumentType.ESTIMATE]: 'estimateAcceptationDays',
  [EnumDocumentType.INVOICE]: 'invoicePaymentDays',
}

export enum EnumDocumentConfigurationStep {
  VAT = 'VAT',
  ESTIMATE_CONDITIONS = 'ESTIMATE_CONDITIONS',
  INVOICE_CONDITIONS = 'INVOICE_CONDITIONS',
  APPEARANCE = 'APPEARANCE',
}
