export enum EnumInvoicingQueryKeys {
  CUSTOMERS_RECORD = 'CUSTOMERS_RECORD',
  CUSTOMERS_LIST = 'CUSTOMERS_LIST',
  DOCUMENTS_RECORD = 'DOCUMENTS_RECORD',
  DOCUMENTS_PDF_RECORD = 'DOCUMENTS_PDF_RECORD',
  DOCUMENT_HTML_PREVIEW = 'DOCUMENT_HTML_PREVIEW',
  DOCUMENTS_LIST = 'DOCUMENTS_LIST',
  INVOICING_SETTINGS = 'INVOICING_SETTINGS',
  LAST_DOCUMENT_NUMBER = 'LAST_DOCUMENT_NUMBER',
}
