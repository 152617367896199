import { DocumentApi } from '@blank/api'
import { AxiosError } from 'axios'
import { isEmpty } from 'lodash'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import {
  FetchDocumentListQueryProps,
  fetchDocumentsList,
} from '../api/fetchDocumentsList'
import { EnumInvoicingQueryKeys } from './keys'

export const useDocumentsListQuery = (
  query?: FetchDocumentListQueryProps,
  queryOptions?: QueryOptions<DocumentApi[], AxiosError>
) => {
  return useQueryWithAuth<DocumentApi[], AxiosError>({
    // The key must only be `[EnumInvoicingQueryKeys.DOCUMENTS_LIST]` when there is no query params
    queryKey: [
      EnumInvoicingQueryKeys.DOCUMENTS_LIST,
      ...(isEmpty(query) ? [] : [query]),
    ],
    queryFn: async (_context, accessToken) => {
      return fetchDocumentsList({ query, accessToken })
    },
    ...queryOptions,
  })
}
