import { EnumDocumentType } from '@blank/api'
import { DOCUMENT_TYPE_SLUGS } from 'modules/invoicing/types/document'
import { useRouter } from 'next/router'

interface Props {
  documentType: EnumDocumentType
  customerId?: string
}

export const useRedirectToDocumentCreationFlow = () => {
  const router = useRouter()

  const redirectToDocumentCreation = ({ documentType, customerId }: Props) => {
    router.push({
      pathname: `/facturation/creation/${DOCUMENT_TYPE_SLUGS[documentType]}`,
      query: customerId ? { customerId } : undefined,
    })
  }

  return { redirectToDocumentCreation }
}
