import { ReactNode, ReactPortal } from 'react'
import { LayerProps, useHover, useLayer, UseLayerOptions } from 'react-laag'

export interface TooltipProps extends LayerProps {
  isOpen: boolean
  renderLayer: (children: ReactNode) => ReactPortal | null
  className?: string
  children?: React.ReactNode
}

export const useTooltip = (options?: Partial<UseLayerOptions>) => {
  const [isOver, hoverProps] = useHover()

  const { triggerProps, layerProps, renderLayer } = useLayer({
    auto: true,
    placement: 'bottom-end',
    possiblePlacements: [
      'bottom-start',
      'bottom-end',
      'bottom-center',
      'top-start',
      'top-center',
      'top-end',
    ],
    triggerOffset: 8,
    ...options,
    isOpen: isOver,
  })

  return {
    registerTooltipTrigger: () => ({
      ...triggerProps,
      ...hoverProps,
    }),
    registerTooltip: (): TooltipProps => ({
      ...layerProps,
      isOpen: isOver,
      renderLayer,
    }),
    isOpen: isOver,
  }
}
