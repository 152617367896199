import {
  CustomerAddressApi,
  CustomerApi,
  EnumCustomerType,
  LegacyCustomerApi,
} from '@blank/api'

export type CustomerTableRow = CustomerApi & {
  formattedName: string
}

export const isLegacyCustomer = (
  customer: CustomerApi
): customer is LegacyCustomerApi => customer.customerType === undefined

export interface CustomerTypeFormData {
  customerType: EnumCustomerType
}

interface BaseCustomerFormData extends CustomerAddressApi {
  email?: string
  phone?: string
}

export interface LegacyCustomerFormData extends BaseCustomerFormData {
  customerType: undefined
  name: string
  sirenOrSiret?: string
  VATNumber?: string
}

export interface PrivateIndividualCustomerFormDataFRA
  extends BaseCustomerFormData {
  customerType: EnumCustomerType.PRIVATE_INDIVIDUAL
  firstName: string
  lastName: string
}

export interface PrivateIndividualCustomerFormDataITA
  extends PrivateIndividualCustomerFormDataFRA {
  codiceFiscale: string
  codiceDestinatario?: string
}

export type PrivateIndividualCustomerFormData =
  | PrivateIndividualCustomerFormDataFRA
  | PrivateIndividualCustomerFormDataITA

export interface LocalEnterpriseCustomerFormDataFRA {
  customerType: EnumCustomerType.LOCAL_ENTERPRISE
  companyName: string
  siren: string
  VATNumber?: string
}

export interface LocalEnterpriseCustomerFormDataITA {
  customerType: EnumCustomerType.LOCAL_ENTERPRISE
  companyName: string
  partitaIva: string
  codiceFiscale: string
  codiceDestinatario?: string
}

export type LocalEnterpriseCustomerFormData = BaseCustomerFormData &
  (LocalEnterpriseCustomerFormDataFRA | LocalEnterpriseCustomerFormDataITA)

export interface ForeignEnterpriseEuCustomerFormData
  extends BaseCustomerFormData {
  customerType: EnumCustomerType.FOREIGN_ENTERPRISE_EU
  companyName: string
  VATNumber: string
}

export interface ForeignEnterpriseOutsideEuCustomerFormData
  extends BaseCustomerFormData {
  customerType: EnumCustomerType.FOREIGN_ENTERPRISE_OUTSIDE_EU
  companyName: string
  identificationNumber?: string
}

export type CustomerFormData =
  | LegacyCustomerFormData
  | PrivateIndividualCustomerFormData
  | LocalEnterpriseCustomerFormData
  | ForeignEnterpriseEuCustomerFormData
  | ForeignEnterpriseOutsideEuCustomerFormData

type PrivateIndividualPostCustomerPayload = {
  customerType: EnumCustomerType.PRIVATE_INDIVIDUAL
  firstName: string
  lastName: string
} & (
  | { codiceFiscale: string; codiceDestinatario?: string }
  | { codiceFiscale?: never; codiceDestinatario?: never }
)

type LocalEnterprisePostCustomerPayload = {
  customerType: EnumCustomerType.LOCAL_ENTERPRISE
  companyName: string
} & (
  | {
      siren: string
      VATNumber?: string
    }
  | {
      partitaIva: string
      codiceFiscale: string
      codiceDestinatario?: string
    }
)

type ForeignEnterpriseEuPostCustomerPayload = {
  customerType: EnumCustomerType.FOREIGN_ENTERPRISE_EU
  companyName: string
} & ({ VATNumber: string } | { partitaIva: string })

interface ForeignEnterpriseOutsideEuPostCustomerPayload {
  customerType: EnumCustomerType.FOREIGN_ENTERPRISE_OUTSIDE_EU
  companyName: string
  identificationNumber?: string
}

interface LegacyPostCustomerPayload {
  customerType: undefined
  name: string
  sirenOrSiret?: string
  VATNumber?: string
}

export type PostCustomerPayload = {
  address: CustomerAddressApi
  email?: string
  phone?: string
} & (
  | PrivateIndividualPostCustomerPayload
  | LocalEnterprisePostCustomerPayload
  | ForeignEnterpriseEuPostCustomerPayload
  | ForeignEnterpriseOutsideEuPostCustomerPayload
  | LegacyPostCustomerPayload
)

export type PatchCustomerPayload = {
  address?: CustomerAddressApi
  email?: string
  phone?: string
  isArchived?: boolean
} & (
  | {
      name?: string
      sirenOrSiret?: string
      VATNumber?: string
    }
  | {
      firstName?: string
      lastName?: string
      codiceFiscale?: string
      codiceDestinadario?: string
    }
  | {
      companyName?: string
      siren?: string
      VATNumber?: string
      partitaIva?: string
      codiceFiscale?: string
      codiceDestinatario?: string
    }
  | {
      companyName?: string
      siren?: string
      identificationNumber?: string
    }
)

export enum EnumCustomerListTabs {
  ACTIVE_CUSTOMERS_TAB = 'activeCustomers',
  ARCHIVED_CUSTOMERS_TAB = 'archivedCustomers',
}

export enum EnumCustomerDetailsTabs {
  INFORMATION_TAB = 'information',
  INVOICES_TAB = 'invoices',
  ESTIMATES_TAB = 'estimates',
}
