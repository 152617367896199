import {
  DocumentApi,
  EnumDocumentStatus,
  EnumDocumentType,
  PaginatedList,
} from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

export interface FetchDocumentListQueryProps {
  type?: EnumDocumentType
  status?: EnumDocumentStatus
  customerId?: string
  transactionLinked?: boolean
}

interface Props {
  query: FetchDocumentListQueryProps | undefined
  accessToken: string
  documents?: DocumentApi[]
  pageToken?: string
}

export const fetchDocumentsList = async ({
  query,
  accessToken,
  documents = [],
  pageToken,
}: Props): Promise<DocumentApi[]> => {
  const apiUrl = '/v1/invoicing/documents'

  const {
    data: { nextPageToken, data: newDocuments },
  } = await apiClient.get<PaginatedList<DocumentApi>>(apiUrl, {
    params: {
      ...query,
      pageToken,
    },
    headers: getAuthorizationHeader(accessToken),
  })

  const cumulativeDocuments = [...documents, ...newDocuments]

  // Recursive call to fetch documents if nextPageToken is not empty
  if (nextPageToken) {
    return fetchDocumentsList({
      query,
      accessToken,
      documents: cumulativeDocuments,
      pageToken: nextPageToken,
    })
  }

  return cumulativeDocuments
}
