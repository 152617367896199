import { useCallback } from 'react'
import { TooltipColor } from './Tooltip'

const THEME = {
  danger: 'bg-danger-200 border border-danger text-info-600',
  primary: 'bg-primary-90 border border-primary text-info-600',
  success: 'bg-success-200 border border-success text-info-600',
  warning: 'bg-warning-200 border border-warning text-info-600',
}

interface Props {
  color: TooltipColor
}

export const useTheme = ({ color }: Props) => {
  const getClass = useCallback(() => {
    return THEME[color]
  }, [color])

  return { themeClass: getClass() }
}
