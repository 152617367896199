import {
  CorporateInformationApi,
  CountryApi,
  CustomerApi,
  EnumCustomerType,
  EnumUserCountry,
} from '@blank/api'
import { EnumHomeCountryCode } from 'config/types'
import { CountryOption } from 'modules/common/components/CountryAutocomplete/CountryAutocomplete'
import { formatAddress } from 'modules/common/lib/helpers/address'
import { getFullName } from 'modules/common/lib/helpers/names/getFullName'
import { CustomerField } from '../components/documents/DocumentFormContainer/types'
import {
  CustomerFormData,
  CustomerTableRow,
  isLegacyCustomer,
  LocalEnterpriseCustomerFormData,
} from '../types/customers'

export const mapCustomerToCustomerForm = (
  customer: CustomerApi
): CustomerFormData => {
  const {
    address: { street, city, zipCode, addressAdditional, country },
    id,
    corporateId,
    updatedAt,
    createdAt,
    isArchived,
    ...rest
  } = customer

  return {
    street,
    city,
    zipCode,
    addressAdditional,
    country,
    ...rest,
  }
}

export const mapCustomerFormToCustomer = (
  values: CustomerFormData
): Partial<CustomerApi> => {
  const { street, city, zipCode, addressAdditional, country, ...rest } = values

  return {
    ...rest,
    address: {
      city,
      street,
      zipCode,
      addressAdditional,
      country,
    },
  }
}

export const mapCustomerToSelectCustomerField = (
  customer: CustomerApi
): CustomerField => {
  const { id, address } = customer

  return {
    label: getCustomerName(customer),
    value: id,
    secondaryLabel: formatAddress(address),
  }
}

export const mapCompanyInformationToLocalEnterpriseCustomerForm = (
  companyInformation: CorporateInformationApi
): LocalEnterpriseCustomerFormData => {
  const { name, address } = companyInformation

  const baseFormData = {
    companyName: name,
    street: address?.street ?? '',
    zipCode: address?.zipCode ?? '',
    city: address?.city ?? '',
  }

  switch (companyInformation.informationType) {
    case EnumUserCountry.ITA: {
      return {
        ...baseFormData,
        customerType: EnumCustomerType.LOCAL_ENTERPRISE,
        partitaIva: companyInformation?.partitaIva ?? '',
        codiceFiscale: companyInformation?.codiceFiscale ?? '',
        codiceDestinatario: companyInformation?.codiceDestinatario ?? '',
        country: EnumHomeCountryCode.ITA,
      }
    }
    case undefined:
    case EnumUserCountry.FRA: {
      return {
        ...baseFormData,
        customerType: EnumCustomerType.LOCAL_ENTERPRISE,
        siren: companyInformation?.siren ?? '',
        VATNumber: companyInformation?.VATNumber ?? '',
        country: EnumHomeCountryCode.FRA,
      }
    }
  }
}

export const getCustomerName = (customer: CustomerApi | undefined): string => {
  if (!customer) {
    return ''
  }
  if (isLegacyCustomer(customer)) {
    return customer.name
  }
  if (customer.customerType === EnumCustomerType.PRIVATE_INDIVIDUAL) {
    return getFullName({
      firstName: customer.firstName,
      lastName: customer.lastName,
    })
  }
  return customer.companyName
}

interface GetForeignCountryArgs {
  countryList: CountryApi[]
  homeCountryCode: EnumHomeCountryCode
}

export const getForeignCountryOptions = ({
  countryList,
  homeCountryCode,
}: GetForeignCountryArgs): CountryOption[] =>
  countryList
    .filter((country) => country.code !== homeCountryCode)
    .map((country) => ({
      label: country.name,
      value: country.code,
      isEU: country.isEU,
    }))

export const buildCustomerTableRows = (
  customers: CustomerApi[] = []
): CustomerTableRow[] =>
  customers.map((customer) => ({
    ...customer,
    formattedName: getCustomerName(customer),
  }))
